import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import paper from "../static/whitepaper.pdf"
import "./whitePaper.css"

export default function WhitePaper() {
  return (
    <div class="pdfWapper">
      <embed class="pdf" src={paper} type="application/pdf" />
    </div>
  )
}
